import * as React from 'react';
import * as styles from './hero.module.scss';
import * as video1 from '../../../videos/video1.mp4';
import * as video2 from '../../../videos/video2.mp4';
import * as video3 from '../../../videos/video3.mp4';
import * as video4 from '../../../videos/video4.mp4';
import BackgroundVideo from './BackgroundVideo';
import classnames from 'classnames';

import * as houzzLogo from '../../../images/houzz-logo-2.svg';
import * as gtnexusLogo from '../../../images/gtnexus_logo.png';
import * as vmwareLogo from '../../../images/vmware_logo.png';

import Carousel from 'nuka-carousel';

const slidesDarkTheme = [true, true, true, false];

interface Props {
  setHeroTheme: (darkTheme: boolean) => void;
}
interface State {
  width: number;
}

export default class Hero extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      width: 1000,
    };
  }

  shouldComponentUpdate(_: any, nextState: State) {
    // Only rerender if a breakpoint has changed and the carousel is to be stopped.
    return (
      (this.state.width < 850 && nextState.width >= 850) ||
      (this.state.width >= 850 && nextState.width < 850)
    );
  }

  componentDidMount() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  updateWidth = () => this.setState({ width: window.innerWidth });

  createOptionalSlides = () => [
    <div
      key={1}
      className={classnames(styles.slideContainer, styles.firstSlideContainer)}
    >
      <div className={styles.videoContainer}>
        <BackgroundVideo videoPath={video1} />
        <div className={classnames(styles.contentContainer, styles.firstSlide)}>
          <div className={styles.content}>
            <h2 className={styles.tagline}>We build dreams into businesses.</h2>
            <h4 className={styles.byline}>
              We partner with entrepreneurs to build enduring technology
              companies that leverage our global network, hands-on operational
              experience, and visionary market insight.
            </h4>
          </div>
        </div>
      </div>
    </div>,
    <div
      key={2}
      className={classnames(styles.slideContainer, styles.secondSlideContainer)}
    >
      <div className={styles.videoContainer}>
        <BackgroundVideo videoPath={video2} />
        <div
          className={classnames(styles.contentContainer, styles.secondSlide)}
        >
          <div className={styles.content}>
            <h2 className={styles.tagline}>
              Driving market evolution through ecosystems
            </h2>
            <h4 className={styles.byline}>
              We love companies that create extraordinary value by establishing
              new ecosystems, marketplaces, and communities.
            </h4>
            <h4 className={styles.byline}>
              GT Nexus (acquired by Infor) revolutionized supply chain
              management by creating new connections between global trading
              partners to help synchronize supply chain operations.
            </h4>
            <img src={gtnexusLogo} />
          </div>
        </div>
      </div>
    </div>,
    <div
      key={3}
      className={classnames(styles.slideContainer, styles.thirdSlideContainer)}
    >
      <div className={styles.videoContainer}>
        <BackgroundVideo videoPath={video3} />
        <div className={classnames(styles.contentContainer, styles.thirdSlide)}>
          <div className={styles.content}>
            <h2 className={styles.tagline}>
              Identifying game-changing products that scale
            </h2>
            <h4 className={styles.byline}>
              We look for exceptional products that create transformative user
              experiences and address huge markets.
            </h4>
            <h4 className={styles.byline}>
              VMWare, which we funded in 1998 through an initial seed round,
              offered a differentiated product with seemingly limitless
              applications. Today its software powers tens of millions of
              virtual machines around the world.
            </h4>
            <img src={vmwareLogo} />
          </div>
        </div>
      </div>
    </div>,
    <div
      key={4}
      className={classnames(styles.slideContainer, styles.fourthSlideContainer)}
    >
      <div className={styles.videoContainer}>
        <BackgroundVideo videoPath={video4} />
        <div
          className={classnames(styles.contentContainer, styles.fourthSlide)}
        >
          <div className={styles.content}>
            <h2 className={styles.tagline}>
              Empowering entrepreneurs to revolutionize industries
            </h2>
            <h4 className={styles.byline}>
              We collaborate with passionate, creative, daring founders who see
              hidden opportunities in the world around us.
            </h4>
            <h4 className={styles.byline}>
              Houzz co-founders Adi Tatarko and Alon Cohen adapted their own
              struggles with their home remodel into an online community that
              has transformed how millions of people design their homes.
            </h4>
            <img src={houzzLogo} />
          </div>
        </div>
      </div>
    </div>,
  ];

  render() {
    const carouselOptions =
      this.state.width < 850
        ? {
            autoplay: false,
            renderBottomCenterControls: () => null,
          }
        : {
            autoplay: true,
          };

    return (
      <div className={styles.slidesContainer}>
        <Carousel
          renderCenterLeftControls={() => null}
          renderCenterRightControls={() => null}
          pauseOnHover={false}
          disableEdgeSwiping={true}
          autoplayInterval={6000}
          wrapAround={true}
          dragging={false}
          swiping={false}
          beforeSlide={(_, nextSlide) =>
            this.props.setHeroTheme(slidesDarkTheme[nextSlide])
          }
          {...carouselOptions}
        >
          {this.state.width >= 850 ? (
            this.createOptionalSlides().map(slide => slide)
          ) : (
            <div
              className={classnames(
                styles.slideContainer,
                styles.firstSlideContainer,
              )}
            >
              <div className={styles.videoContainer}>
                <div
                  className={classnames(
                    styles.contentContainer,
                    styles.firstSlide,
                  )}
                >
                  <div className={styles.content}>
                    <h2 className={styles.tagline}>
                      We build dreams into businesses.
                    </h2>
                    <h4 className={styles.byline}>
                      We partner with entrepreneurs to build enduring technology
                      companies that leverage our global network, hands-on
                      operational experience, and visionary market insight.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Carousel>
      </div>
    );
  }
}
