import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Header from '../components/Header';
import SEO from '../components/SEO';
import Hero from '../components/index/Hero';

interface Props {}
interface State {
  darkHeroTheme: boolean;
}

export default class IndexPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      darkHeroTheme: true,
    };
  }

  setHeroTheme = (darkTheme: boolean) =>
    this.setState({ darkHeroTheme: darkTheme });

  render() {
    return (
      <Layout>
        <SEO pageTitle="Home" description="" />
        <Header background={false} light={this.state.darkHeroTheme} />
        <Hero setHeroTheme={this.setHeroTheme} />
      </Layout>
    );
  }
}
